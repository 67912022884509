import Head from 'next/head'
import React from 'react'

import Public from '@dashboard/components/DashboardPublic'

import Registration from '@dashboard/components/Registration'

import { NextPageContext } from 'next'
import { redirect } from '@dashboard/lib/server'
import { ParsedUrlQuery } from 'querystring'
import { getQueryParam } from '@dashboard/lib/browser'

interface OwnProps {
  query: ParsedUrlQuery
  asPath: string
  gToken?: string
}

const shouldUseOnboardingFlowWithLookup = () => {
  // Some percent of users will see the onboarding flow with lookup (default 5%)
  return (
    Math.random() <
    (process.env.ONBOARDING_PERCENTAGE
      ? parseFloat(process.env.ONBOARDING_PERCENTAGE)
      : 0)
  )
}

export default class RegistrationPage extends React.Component<OwnProps> {
  static async getInitialProps(ctx: NextPageContext) {
    const { query, asPath } = ctx
    const gToken = getQueryParam(query.gToken)

    // Redirect to new onboarding flow for ordinary sign up (no query params)
    ;(query['public_assessment'] || !Object.keys(query).length) &&
      shouldUseOnboardingFlowWithLookup() &&
      redirect(ctx, '/app/sign-up')

    return {
      query,
      gToken,
      asPath,
    }
  }

  render() {
    const { query, gToken, asPath } = this.props
    return (
      <Public asPath={asPath}>
        <Head>
          <title>Crystal - Register for Crystal</title>
        </Head>
        <Registration query={query} gToken={gToken} asPath={asPath} />
      </Public>
    )
  }
}
