import { RootState } from '@dashboard/store'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction, bindActionCreators } from 'redux'
import * as ls from 'local-storage'

import { registerAttributes } from '@dashboard/store/actions/user/registration'
import { suggestCompany } from '@dashboard/store/actions/company/suggest'
import { runTasks } from '@dashboard/store/actions/tasks'

import { LoadingStates } from '@dashboard/lib/types'

import Component, { StateProps, DispatchProps, OwnProps } from './component'
import { getQueryParam } from '@dashboard/lib/browser'
import { loadMe } from '@dashboard/store/actions/user/me'
import {
  loadTeamSimple,
  showSimpleTeamKey,
} from '@dashboard/store/actions/team/show_simple'

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
  const { query } = ownProps
  const { suggestStates, suggestResults } = states.company
  const { me: authUser, meState, registrationState } = states.user
  const { loadTeamSimpleStates, loadTeamSimpleResults } = states.team
  const teamId = getQueryParam(query.team_id)
  const teamSimpleKey = showSimpleTeamKey(teamId || '')
  const teamSimpleState = loadTeamSimpleStates[teamSimpleKey] || 'default'
  const teamSimpleResult = loadTeamSimpleResults[teamSimpleKey] || null

  const afterLoginPath = ls.get<string | undefined>('afterLoginPath')
  const justFinishedPubAssessment = ls.get('pubAssessFinished') == 'true'

  let uiState: LoadingStates = 'default'
  if (
    registrationState.state === 'loading' ||
    registrationState.state === 'success'
  )
    uiState = 'loading'
  else if (registrationState.state == 'failure') uiState = 'failure'

  const parsedQuery = ownProps.query

  const companyDomain = getQueryParam(parsedQuery.inviter_company_domain)
  const companyInviteToken = getQueryParam(parsedQuery.company_invite_token)
  const companyName = getQueryParam(parsedQuery.inviter_company_name)
  const email =
    getQueryParam(parsedQuery.email) ||
    getQueryParam(parsedQuery.invitee_email) ||
    ''
  const firstName = parsedQuery.firstName as string
  const lastName = parsedQuery.lastName as string
  const inviteToken = parsedQuery.invite_token as string
  const showMarketing = parsedQuery.info ? true : false
  const facebookID = parsedQuery.facebook_id as string
  const publicAssessment = parsedQuery.public_assessment !== undefined
  const actions = parsedQuery.actions as string

  if (getQueryParam(parsedQuery.slack_team_id)) {
    ls.set('slackTeamId', getQueryParam(parsedQuery.slack_team_id))
  }

  if (facebookID) ls.set('facebookID', facebookID)

  let profileId = getQueryParam(parsedQuery.profile_id)
  if (profileId) ls.set('invite_id', profileId)
  else if (ls.get('invite_id')) profileId = ls.get('invite_id')

  return {
    afterLoginPath,
    companyDomain,
    companyInviteToken,
    companyName,
    email,
    firstName,
    justFinishedPubAssessment,
    lastName,
    profileId,
    registrationState,
    inviteToken,
    uiState,
    showMarketing,
    facebookID,
    publicAssessment,
    suggestStates,
    suggestResults,
    actions,
    authUser,
    meState,
    teamSimpleState,
    teamSimpleResult,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): DispatchProps => {
  return {
    registerAttributes: bindActionCreators(registerAttributes, dispatch),
    suggestCompany: bindActionCreators(suggestCompany, dispatch),
    runTasks: bindActionCreators(runTasks, dispatch),
    loadTeamSimple: bindActionCreators(loadTeamSimple, dispatch),
    loadMe: bindActionCreators(loadMe, dispatch),
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Component)
